.wrapper {
    display: flex;
    padding: 40px 0px;
    align-items: center;
    flex-direction: column;

    .image {
        max-width: 100%;
        margin-bottom: 24px;
    }

    .title {
        font-size: 32px;
        color: #37383a;
        font-weight: bold;
        line-height: 38px;
        text-align: center;
        margin-bottom: 8px;
    }

    .text {
        font-size: 20px;
        color: #37383a;
        line-height: 24px;
        text-align: center;
        font-weight: normal;
        margin-bottom: 16px;
    }

    .link {
        padding: 15px;
        color: #fff;
        border-radius: 8px;
        text-decoration: none;
        background-color: #1479FF;
    }
}
